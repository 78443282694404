<template>
  <div>
    <section class="product-list-heading mb-0 box-my-account">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <h1 class="mt-4 text-center border-bottom30">Our Review</h1>
          </div>
          <div class="col-md-4 p-3" v-for="(influencer, i) in influencers" :key="i">
            <div class="w-100">
              <img
                class="card-img-top rounded"
                :src="influencer.image"
                :alt="`${influencer.name}, providing a testimonial about Whizliz Marketplace.`"
                style="height: 438px; object-fit: cover"
              />
              <div class="card-body" style="margin-bottom: 12px">
                <h5 class="mb-0" style="font-weight: bold">
                  {{ influencer.name }}
                </h5>
                <small><a :href="influencer.url" target="_blank">{{ influencer.ig_account }}</a></small>
                <p class="card-text pt-3" v-html="influencer.testimony_description"></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { setMetainfo } from "@/_helpers";
import axios from "axios"

export default {
  name: "Testimony",
  metaInfo() {
    return setMetainfo('Whizliz Review', 'Masih ragu kalau Whizliz bukan penipu? Berikut review para customer Whizliz, Beli Perhiasan Emas dan Berlian asli hanya di Whizliz')
  },
  data() {
    return {
      influencers: []
    };
  },
  computed: {
    apiUrl: () => process.env.VUE_APP_API_URL,
    apiKey: () => process.env.VUE_APP_API_KEY
  },
  mounted() {
    axios.get(this.apiUrl + 'users/testimony/list', {
      headers: {
        api_key: this.apiKey,
        "Content-Type": "application/json"
      }
    })
    .then(res => {
      this.influencers = res.data.data
      setTimeout(() => {
        window.prerenderReady = true;
      }, 2000)
    })
  }
};
</script>
